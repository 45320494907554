$zIndexHeader: 900;
$zIndexAutoComplete: 600;
$zIndexBottomMenu: 200;

$zIndexMobileMenuBackDrop: 1000;
$zIndexMobileMenu: 2000;
$zIndexModal: 5000;

@mixin defaultShadow {
  box-shadow: 0px 0.1px 0.3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 20%);
}

@mixin fadeOut {
  visibility: hidden;
  transition: opacity 300ms, visibility 301ms;
  opacity: 0;
}

@mixin fadeIn {
  visibility: visible;
  transition: opacity 300ms;
  opacity: 1;
}

@mixin showLeftToRight {
  visibility: visible;
  transition: left 400ms;
  left: 0;
  right: initial;
}

@mixin hideRighToLeft {
  visibility: visible;
  transition: left 400ms, visibility 401ms;
  left: -100vw;
  right: initial;
}

@mixin showRightToLeft {
  visibility: visible;
  transition: right 400ms;
  right: 0;
  left: initial;
}

@mixin hideLeftToRight {
  visibility: visible;
  transition: right 400ms, visibility 401ms;
  right: -100vw;
  left: initial;
}

@mixin showBottomToTop {
  visibility: visible;
  transition: top 500ms, opacity 500ms;
  top: 0;
  opacity: 1;
}

@mixin hideTopToBottom {
  visibility: hidden;
  transition: top 500ms, visibility 701ms, opacity 500ms;
  top: 100vw;
  opacity: 0;
}
