@import "~@lc/ui/styles/settings/_colors.scss";

.link {
  a {
    color: $primary-green;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.noUnderline {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.naked {
  a {
    color: inherit;
  }
}
