@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

@mixin modaSmSize {
  height: initial;
  left: 50%;
  max-height: 100vh;
  max-width: 560px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.backdrop {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  height: 100vh;
  width: 100vw;
  z-index: $zIndexMobileMenuBackDrop;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: $zIndexModal;

  .close {
    margin-left: auto;
  }

  .backbutton {
    margin-right: auto;
  }

  &.visible {
    @include breakpoint-xs-only {
      @include showBottomToTop;
      padding-top: 10px;
    }

    @include breakpoint-sm-up {
      @include fadeIn;
      @include modaSmSize;
    }
  }

  &.hidden {
    @include breakpoint-xs-only {
      @include hideTopToBottom;
    }

    @include breakpoint-sm-up {
      @include fadeOut;
      @include modaSmSize;
    }
  }

  .modalContent {
    background-color: #ffffff;
    border-radius: 5px;
    height: 100%;
    padding-bottom: 40px;
    overflow: auto;
    width: 100%;

    .header {
      align-items: center;
      display: grid;
      grid-template-columns: 100fr 1fr;
      text-align: center;
    }
  }
}
