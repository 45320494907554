@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.howLCWorks {
  background-color: #f0f8f5;
  margin-top: 16px;
  padding: 16px;

  &.naked {
    background-color: transparent;
  }

  .maxWidth {
    margin: 0 auto;
    max-width: 1136px;
  }
}
