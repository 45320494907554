@import "./settings/normlize.min.css";

* {
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  font-family: Montserrat, -apple-system, "system-ui", "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
}

b {
  font-weight: 600 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
