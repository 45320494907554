@import "~@lc/ui/styles/settings/_colors.scss";

.badge {
  position: relative;

  .hasNotification {
    background-color: $secondary-red;
    border-radius: 50%;
    position: absolute;
    height: 8px;
    width: 8px;
    z-index: 1;
  }
}
