@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.backdrop {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  height: 100vh;
  width: 100vw;
  z-index: $zIndexMobileMenuBackDrop;
}

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 85vw;
  width: 100vw;
  z-index: $zIndexModal;

  @include breakpoint-md-up {
    max-width: 50vw;
  }

  &.LTR {
    &.close {
      @include hideRighToLeft;
    }

    &.open {
      @include showLeftToRight;
    }
  }

  &.RTL {
    &.close {
      @include hideLeftToRight;
    }

    &.open {
      @include showRightToLeft;
    }
  }

  .content {
    background-color: #ffffff;
    height: 100%;
    overflow: auto;
  }
}
