@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.autocomplete {
  @include defaultShadow;
  background-color: $secondary-gray-very-light;
  border-radius: 4px;
  list-style: none;
  left: 0;
  margin: 0;
  padding: 8px 0;
  position: absolute;
  width: 100%;
  z-index: $zIndexAutoComplete;

  .active {
    background-color: rgba(54, 191, 236, 0.2);
  }

  .item {
    color: $text-gray-dark;
    cursor: pointer;
    font-size: 16px;
    padding: 6px 16px;
    &:hover,
    &:focus {
      background-color: rgba(54, 191, 236, 0.2);
    }
  }
}
