@import "~@lc/ui/styles/settings/_colors.scss";

.button {
  align-items: center;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  gap: 10px;
  justify-content: center;
  width: fit-content;

  &.loading {
    svg {
      width: 20px;
    }
  }

  .icon {
    display: flex;
    margin-left: -5px;
  }

  &.fullWidth {
    width: 100%;
  }

  &.xs {
    height: 32px;
    font-size: 12px;
    padding: 8px 8px;
  }
  &.sm {
    height: 40px;
    font-size: 14px;
    padding: 10px 16px;
  }
  &.md {
    height: 54px;
    font-size: 16px;
    padding: 15px 24px;
  }
  &.lg {
    padding: 20px 24px;
    font-size: 16px;
    height: 64px;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 1;
  }

  &.asLink {
    cursor: pointer;
    height: initial;
    margin: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  &.noPadding {
    padding: 0;
  }
}

.primary {
  &.contained {
    background-color: $primary-blue;
    color: #ffffff;
  }
}
.secondary {
  &.contained {
    background-color: $primary-green;
    color: #ffffff;
  }
}
.primary {
  &.outline {
    background-color: transparent;
    border: 2px solid $primary-blue;
    color: $primary-blue;
  }
}
.secondary {
  &.outline {
    background-color: transparent;
    border: 2px solid $primary-green;
    color: $primary-green;
  }
}

.naked {
  background-color: transparent;
  font-weight: initial;
}
