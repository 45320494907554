@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.wrapper {
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px -3px rgb(0 0 0 / 20%),
    0px -3px 0px 1px rgb(0 0 0 / 14%), 0px 0px 8px 0px rgb(0 0 0 / 12%);
  display: none;
  gap: 10px;
  height: 85px;
  justify-content: space-between;
  padding: 0 16px;
  position: sticky;
  top: 0;
  max-height: 85px;
  // width: calc(100% - 32px);
  z-index: $zIndexHeader;

  .maxWidth {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1136px;
    width: 100%;
  }

  .logo,
  .loginBtn {
    display: none;
  }
  .searchInput {
    width: calc(100% - 32px);
  }

  @include breakpoint-md-up {
    display: flex;
    .logo {
      height: 100;
      width: 200;
    }
    .logo,
    .loginBtn {
      display: block;
    }
    .logoMobile {
      display: none;
    }
    .loginBtn {
      display: block;
      max-width: 128px;
      min-width: 128px;

      .innerButton {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      button {
        border-radius: 100px;
        max-height: 48px;
      }
    }
    .searchInput {
      display: flex;
      max-width: 552px;
    }
  }

  .menuHeader {
    position: absolute;
    right: 26px;
    width: 256px;
  }

  .menuList {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    list-style: none;
    padding: 24px;
    margin: 0;
  }

  .menuItem {
    padding-bottom: 24px;
    &:last-child {
      padding-bottom: 0;
    }
  }

  .bold {
    font-weight: 700;
  }
}
