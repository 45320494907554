@import "~@lc/ui/styles/settings/_breakpoints.scss";

.main {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: calc(100vh);

  &.noFooter {
    @include breakpoint-md-up {
      min-height: calc(100vh - 85px); // minus header
    }
  }
}
