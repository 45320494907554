@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.wrapper {
  position: relative;
  height: 50px;
  width: 100%;

  &.noLabel {
    position: initial;
    height: initial;
  }
}

.label {
  background: #fff;
  color: $secondary-gray-dark;
  font-size: 14px;
  font-weight: normal;
  left: 0;
  position: absolute;
  pointer-events: none;
  padding: 0;
  top: 10px;
  transition: 0.2s ease all;

  @include breakpoint-sm-up {
    font-size: 16px;
    top: 9px;
  }

  &.outline {
    left: 5px;
    padding: 0 5px;
  }

  &.error {
    color: $secondary-red;
    border-color: $secondary-red;
  }
}

.errorMessage {
  color: $secondary-red;
  font-size: 12px;
}

.input,
.select {
  border: 0;
  background-color: #fff;
  box-sizing: border-box;
  color: $text-graphite;
  display: block;
  font-size: 16px;
  height: 36px;
  padding-top: 8px;
  width: 100%;

  &.outline {
    border: 1px solid $secondary-gray-light;
    border-radius: 4px;
    padding-top: 5px;
    padding-left: 12px;
  }

  &.default {
    border-bottom: 1px solid $secondary-gray-light;
  }

  &.primary {
    &:focus {
      border-color: $primary-blue;
    }
  }

  &.secondary {
    &:focus {
      border-color: $primary-green;
    }
  }

  &.error {
    border-color: $secondary-red;
  }

  &.naked {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline: none;

    ~ .label {
      top: -8px;
      font-size: 14px;
      &.primary {
        color: $primary-blue;
      }
      &.secondary {
        color: $primary-green;
      }
    }
  }
}
.input {
  &:not(:placeholder-shown) ~ .label {
    font-size: 13px;
    top: -8px;
  }
}
.select {
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='383838'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 12px;
  background-position: calc(100% - 8px) calc(100% - 8px);
  background-repeat: no-repeat;

  &:not([data-value=""]) ~ .label {
    top: -8px;
    font-size: 13px;
  }
}

.suggestionsOpen {
  @include fadeIn;
}

.suggestionsClose {
  @include fadeOut;
}
