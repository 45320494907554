@import "@lc/ui/styles/settings/_breakpoints.scss";
@import "@lc/ui/styles/settings/_colors.scss";

.common {
  margin-top: 5px;
  margin-bottom: 10px;

  .common {
    margin: 0;
  }
}

.noMargin {
  margin: 0;
}

.noOverflow {
  overflow: hidden;
}

.body1 {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

.body2 {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}

.body3 {
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
}

.h1 {
  font-size: 26px;
  line-height: 32px;
}

.h2 {
  font-size: 24px;
  line-height: 30px;
}

.h3 {
  font-size: 22px;
  line-height: 28px;
}

.h4 {
  font-size: 20px;
  line-height: 24px;
}

.h5 {
  font-size: 18px;
  line-height: 22px;
}

.h6 {
  font-size: 16px;
  line-height: 22px;
}

.primary {
  color: $text-graphite;
}

.green {
  color: $primary-green;
}

.secondary {
  color: $text-gray-dark;
}

.white {
  color: #ffffff;
}
