@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.welcomeModal {
  padding: 24px;
  text-align: center;

  .logo {
    margin-bottom: 40px;
  }

  .explorerBtn {
    margin-top: 40px;
  }
}
