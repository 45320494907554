.avatar {
  align-items: center;
  background-color: #455a64;
  border-radius: 50%;
  display: flex;
  height: 36px;
  justify-content: center;
  overflow: hidden;
  min-width: 36px;
}
