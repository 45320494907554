@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.wrapper {
  padding: 0 40px;

  .title {
    font-weight: 600;
    padding: 24px 8px;
    text-align: center;
  }

  .subtitle {
    font-weight: 600;
  }
  .resume {
    h4 {
      font-size: 14px;
      @include breakpoint-md-up {
        font-size: 20px;
      }
    }
    a {
      color: $primary-blue;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .knowMore {
    align-items: center;
    color: $primary-green;
    display: flex;
    font-weight: 500;
    gap: 10px;
  }
}
