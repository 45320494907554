$primary-blue: #0031ff;
$primary-green: #34ba92;
$primary-graphite: #383838;

$secondary-blue-darkk: #000380;
$secondary-blue-medium: #0c72e8;
$secondary-blue-light: #37c0ed;
$secondary-green-light: #3fe1b0;
$secondary-orange: #fa673b;
$secondary-red: #e82a16;
$secondary-yellow: #f0b60f;
$secondary-gray: #a6a6a6;
$secondary-gray-dark: #797979;
$secondary-gray-light: #e6e6e6;
$secondary-gray-very-light: #f6f6f6;

$text-graphite: $primary-graphite;
$text-gray: $secondary-gray;
$text-gray-dark: $secondary-gray-dark;

$feedback-bg-success: #cee9df;
$feedback-bg-error: #eda89e;
$feedback-bg-info: #d5eefa;
