$xs: 0;
$sm: 600px;
$md: 960px;
$lg: 1200px;
$xl: 1536px;

@mixin breakpoint-xs-up {
  @media (min-width: #{$xs}) {
    @content;
  }
}

@mixin breakpoint-xs-only {
  @media (min-width: #{$xs}) and (max-width: #{$sm}) {
    @content;
  }
}

@mixin breakpoint-sm-up {
  @media (min-width: #{$sm}) {
    @content;
  }
}

@mixin breakpoint-md-up {
  @media (min-width: #{$md}) {
    @content;
  }
}

@mixin breakpoint-lg-up {
  @media (min-width: #{$lg}) {
    @content;
  }
}

@mixin breakpoint-xl-up {
  @media (min-width: #{$xl}) {
    @content;
  }
}
