@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.wrapper {
  box-shadow: 0px 4px 7px 0px rgb(0 0 0 / 20%);
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  height: 48px;
  padding: 0 16px;
  position: relative;

  @include breakpoint-md-up {
    @include defaultShadow;
    width: 100%;
  }

  .icon {
    color: $primary-green;
    display: flex;
    font-size: 24px;
  }

  input {
    color: $text-graphite;
    border: 0;
    outline: 0;
    width: 100%;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: $text-gray;
    }
  }

  .autocomplete {
    @include defaultShadow;
    background-color: $secondary-gray-very-light;
    border-radius: 4px;
    list-style: none;
    margin: 0;
    padding: 8px 0;
    position: absolute;
    top: 60px;
    width: 552px;
    z-index: $zIndexAutoComplete;

    .active {
      background-color: rgba(54, 191, 236, 0.2);
    }

    .item {
      color: $text-gray-dark;
      cursor: pointer;
      padding: 6px 16px;
      &:hover,
      &:focus {
        background-color: rgba(54, 191, 236, 0.2);
      }
    }
  }
}
