@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.innerWrapper {
  @include breakpoint-md-up {
    margin: 0 auto;
    max-width: 1136px;
    display: flex;
    justify-content: space-between;
  }
}

.wrapper {
  background-color: $secondary-gray-very-light;
  padding: 0 16px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 16px;
    }

    &.social {
      display: flex;
      gap: 30px;
      justify-content: center;
      padding: 40px 0;
      span {
        font-size: 22px;
        @include breakpoint-md-up {
          font-size: 28px;
        }
      }
    }
  }

  .ourLinks {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    @include breakpoint-md-up {
      flex-direction: row;
      gap: 150px;
    }
  }

  .aboutUs {
    display: block;
    span {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    @include breakpoint-md-up {
      display: none;
    }
  }
}
